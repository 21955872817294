export const CORES_VALUES = [
  {
    id: 1,
    coreValueTitle: 'We give creatives a place to call home.',
    sideTitle: 'Foster & Develop Meaningful Stories',
    sideText:
      'We believe in celebrating diversity and developing new voices to empower the next generation of creators. We are a safe place to incubate new ideas, develop stories, and to experiment with original ways of expressing ourselves in digital formats.',
  },
  {
    id: 2,
    coreValueTitle: 'Originality means taking risks.',
    sideTitle: 'We’re comfortable being uncomfortable.',
    sideText:
      'To be original is to be a risk taker - to expand upon what’s possible. Anything that is new and different can be a risk, but it is necessary for growth. Our creative team will break the risk down into a well thought out process and approach to ensure your project is a success.',
  },
  {
    id: 3,
    coreValueTitle: 'Fearlessly embrace new ideas & challenges.',
    sideTitle: 'Every challenge is an opportunity for growth.',
    sideText:
      'To create award-winning content we must fearlessly embrace new ideas and challenges. We believe in encouraging all voices and listening to creative solutions to discover new possibilities.',
  },
  {
    id: 4,
    coreValueTitle: 'Empathy and integrity is at the core of our work.',
    sideTitle: 'Be true to who you are.',
    sideText:
      'Being a storyteller means embodying empathy, actively listening to and appreciating diverse perspectives from various life experiences, and representing them honestly.',
  },
  {
    id: 5,
    coreValueTitle: 'We believe in innovation through inclusivity.',
    sideTitle: ' The best idea wins.',
    sideText:
      'We believe that the best idea should always prevail, and we recognize that great ideas can originate from anyone, regardless of their role or background.',
  },
];
