/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import gsap from 'gsap';
import ReactPlayer from 'react-player';
import { Footer, Layout, VideoModal } from '../../shared/ui';
import { MinusIcon, PlusIcon, PlayIcon } from '../../assets';
import { CLIENT_LIST, CLIENT_LIST2 } from '../../data/ClientList';
import { CORES_VALUES } from '../../data/about';
import { useModal } from '../../shared/hooks';
import { hideCursor, showCursor } from '../../shared/ui/Cursor';

const AboutUs = (props) => {
  const data = props.data.allContentfulAbout.nodes[0];
  const [isShow, setIsShow] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isPlaying, setIsPlaying] = useState(false);
  const [accordianHeight, setAccordianHeight] = useState(0);
  const [selectedCore, setSelectedCore] = useState(CORES_VALUES[0]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const videoModalOptAbout = useModal();

  useEffect(() => {
    if (data?.title) {
      document.title = data.title;
    }
  }, []);

  useEffect(() => {
    if (typeof document !== 'undefined') {
      setAccordianHeight(document.getElementById('accordianRef')?.scrollHeight);
    } else {
      setAccordianHeight(0);
    }
  }, []);

  const handlePlayPause = () => {
    setSelectedVideo(
      data?.vimeoVideos?.[1] ||
        'https://videos.ctfassets.net/xu8n095wfb6m/6dhpvjWJYP5R4ZbL3MEPAN/19056a2d9d607329346e4221dd0ac44a/dr-teals-stay-hungry-derrick-henry__1_.mp4'
    );
    videoModalOptAbout.onOpen();
  };

  const handleHoverCore = (c) => {
    if (c?.id !== selectedCore?.id) {
      gsap.to('.about-core-values', {
        opacity: 0,
        delay: 0,
        duration: 0.3,
        ease: 'power2.out',
        onComplete: () => {
          setSelectedCore(c);
          gsap.to('.about-core-values', {
            opacity: 1,
            delay: 0,
            duration: 0.3,
            ease: 'power2.out',
          });
        },
      });
    }
  };

  return (
    <Layout>
      <VideoModal {...videoModalOptAbout}>
        <div
          className=" w-[95vw] sm:w-[74.5vw] h-[70vh] sm:h-[calc(100vh-130px)]"
          onMouseEnter={hideCursor}
          onMouseLeave={showCursor}
        >
          <ReactPlayer
            className="react-player"
            url={selectedVideo}
            controls
            playing
            width="100%"
            height="100%"
            muted
            loop
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              objectFit: 'contain',
              width: '100%',
              height: '100%',
            }}
          />
        </div>
      </VideoModal>

      <div className="w-[100%] font-jakarta pt-14 sm:pt-20">
        <p className=" xl:text-4xl text-2xl mx-6 xl:max-w-[60vw] lg:max-w-[680px] w-auto ">
          We’re a creative production company that helps brands bridge the gap
          with culture, media and entertainment. We work with the world’s
          leading agencies to create award-winning content that shapes culture.
        </p>
        <div className="flex justify-between md:gap-16 gap-8 w-auto m-auto my-12 lg:my-0 ">
          <div className="relative mt-28 md:mt-52 lg:mt-24 w-[200%] md:w-[100%] lg:w-[80%] xl:w-[64%]">
            <video
              loop
              autoPlay
              data-autoplay
              id="aboutVid1"
              muted
              preload="auto"
              data-keepplaying
              playsInline
              controls={false}
              className=" w-full object-cover"
            >
              <source
                src={data?.videos?.[0]?.url}
                data-wf-ignore="true"
                type="video/mp4"
              />
            </video>
            {/* <StaticImage
              src="../../assets/about-us-image-2.jpg"
              alt="about-image-2"
              class=" w-full h-auto xl:h-[32vw] bg-cover object-cover"
            /> */}
          </div>
          <div className=" xl:-mt-60 lg:-mt-40 min-[1600px]:w-[30%] min-[1400px]:absolute min-[1400px]:right-0 ">
            <StaticImage
              src="../../assets/about-image.jpg"
              alt="aboutt-image"
              className="w-full  h-auto xl:h-[40vw] bg-cover object-cover"
            />
          </div>
        </div>
      </div>
      <div className="md:grid md:grid-cols-12 gap-6 mx-4 md:my-32 lg:mx-[8%] md:mx-16">
        {/* <div className=" col-span-3 max-md:my-12">
          <span className=" max-md:text-sm text-[#787878]  font-jakarta font-normal">
            Founded in
          </span>
          <p className=" text-2xl max-md:text-xl font-medium mt-2  font-jakarta">
            2018
          </p>
        </div> */}
        <div className=" col-span-6 font-jakarta">
          <span className="max-md:text-sm text-[#787878]  font-jakarta font-normal">
            Our Core Values
          </span>
          {CORES_VALUES.map((c, i) => (
            <p
              key={`core-${i}`}
              className="mt-2 w-fit text-[#E9E9E9] max-md:text-sm "
              onMouseEnter={() => handleHoverCore(c)}
            >
              {c?.coreValueTitle}
            </p>
          ))}
        </div>
        <div className="about-core-values col-span-6 sm:max-w-[80%] font-jakarta max-md:my-12">
          <span className="max-md:text-sm text-[#787878]  font-jakarta font-normal">
            {selectedCore?.sideTitle}
          </span>
          <p className="mt-2 text-[#E9E9E9] text-sm">
            {selectedCore?.sideText}
          </p>
        </div>
      </div>
      <div className="mx-4 md:grid md:grid-cols-12 gap-6 lg:mx-[8%] md:mx-16">
        <div className=" col-span-6">
          <span className=" max-md:text-sm text-[#787878]  font-jakarta font-normal">
            Our Studio
          </span>
          <h1 className="mt-2 md:text-2xl text-xl font-jakarta font-bold">
            Strategy & Creative Development
          </h1>
          <h2 className="mt-2 md:text-2xl text-xl  opacity-30 font-jakarta">
            Innovation & Experiential
          </h2>
          <h2 className="mt-2 md:text-2xl text-xl  opacity-30 font-jakarta">
            Production, Post, VFX
          </h2>
        </div>
        <div className=" col-span-6 font-jakarta max-md:mt-6">
          <p className=" leading-7 font-normal text-sm sm:text-base lg:text-xl">
            Our strategy and creative development teams collaborate closely with
            our client partners to strategize, devise, and refine brand
            concepts. This can encompass comprehensive positioning, strategic
            planning, and inventive initiatives for a new project, or the
            repositioning and rejuvenation of a brand identity that hasn't kept
            pace with a business's evolution.
          </p>
          <p className=" leading-7 font-normal mt-6 text-sm sm:text-base lg:text-xl">
            We maintain close collaboration with stakeholders to ensure that the
            final outcome isn't just a visually appealing design exercise but a
            vital asset to the business. While it's exciting to envision
            mock-ups of branded merchandise in a design proposal, we recognize
            that the true value of an identity lies in its practical applicatio
          </p>
          <p className=" mt-[30px] text-sm opacity-30 uppercase underline-offset-6 underline">
            See the work
          </p>
        </div>
      </div>

      <div className=" hidden sm:block  mb-8 sm:mb-16 md:mx-8 mx-4 md:mt-36 mt-12">
        <div className="relative h-[50vw] sm:h-[40vw] w-full">
          {!isPlaying && (
            <div
              onClick={handlePlayPause}
              className=" absolute top-0 left-0 z-30 w-full h-full flex items-center justify-center mix-blend-exclusion"
            >
              <div className="w-[clamp(48px,5vw,72px)] h-[clamp(48px,5vw,72px)] bg-white flex items-center justify-center rounded-full">
                <PlayIcon />
              </div>
            </div>
          )}
          {data?.thumbImgs?.[0] && (
            <GatsbyImage
              alt={data?.thumbImgs?.[0]?.gatsbyImage}
              objectFit="cover"
              image={data?.thumbImgs?.[0]?.gatsbyImage}
              className="object-cover h-full sm:h-[40vw] w-full mb-11"
            />
          )}
          {/* {data?.vimeoVideos?.[1] && (
            <ReactPlayer
              className="react-player"
              url={
                data?.vimeoVideos?.[1] ||
                'https://videos.ctfassets.net/xu8n095wfb6m/6dhpvjWJYP5R4ZbL3MEPAN/19056a2d9d607329346e4221dd0ac44a/dr-teals-stay-hungry-derrick-henry__1_.mp4'
              }
              controls={false}
              playing={false}
              width="100%"
              height="100%"
              muted
              light
              playIcon={<div />}
              style={{
                objectFit: 'cover',
                width: '100%',
                height: '100%',
              }}
            />
          )} */}
        </div>
      </div>

      <div className="grid grid-cols-12 lg:mx-[8%] md:mx-16 mx-4 m-auto md:my-[150px] my-12 xl:gap-x-[80px] xl:gap-y-[63px] lg:gap-x-[44px] lg:gap-y-[33px] gap-[24px]">
        <div className="xl:col-span-2 md:col-span-3 col-span-4 text-center ">
          <StaticImage
            src="../../assets/logo/1.png"
            alt="aboutt-image"
            className="max-[375px]:w-full w-[100px] md:w-[118px] xl:w-full h-auto object-contain"
          />
        </div>
        <div className="xl:col-span-2 md:col-span-3 col-span-4 text-center ">
          <StaticImage
            src="../../assets/logo/2.png"
            alt="aboutt-image"
            className="max-[375px]:w-full w-[100px] md:w-[118px] xl:w-full h-auto object-contain"
          />
        </div>
        <div className="xl:col-span-2 md:col-span-3 col-span-4 text-center ">
          <StaticImage
            src="../../assets/logo/3.png"
            alt="aboutt-image"
            className="max-[375px]:w-full w-[100px] md:w-[118px] xl:w-full h-auto object-contain"
            quality={100}
            objectFit="contain"
            imgStyle={{
              objectFit: 'contain',
              width: '100%',
              maxWidth: '100%',
            }}
          />
        </div>
        <div className="xl:col-span-2 md:col-span-3 col-span-4 text-center ">
          <StaticImage
            src="../../assets/logo/4.png"
            alt="aboutt-image"
            className="max-[375px]:w-full w-[100px] md:w-[118px] xl:w-full h-auto object-contain"
          />
        </div>
        <div className="xl:col-span-2 md:col-span-3 col-span-4 text-center ">
          <StaticImage
            src="../../assets/logo/5.png"
            alt="aboutt-image"
            className="max-[375px]:w-full w-[100px] md:w-[118px] xl:w-full h-auto object-contain"
            quality={100}
            objectFit="contain"
          />
        </div>
        <div className="xl:col-span-2 md:col-span-3 col-span-4 text-center ">
          <StaticImage
            src="../../assets/logo/6.png"
            alt="aboutt-image"
            className="max-[375px]:w-full w-[100px] md:w-[118px] xl:w-full h-auto object-contain"
          />
        </div>
        <div className="xl:col-span-2 md:col-span-3 col-span-4 text-center ">
          <StaticImage
            src="../../assets/logo/7.png"
            alt="aboutt-image"
            className="max-[375px]:w-full w-[100px] md:w-[118px] xl:w-full h-auto object-contain"
          />
        </div>
        <div className="xl:col-span-2 md:col-span-3 col-span-4 text-center ">
          <StaticImage
            src="../../assets/logo/8.png"
            alt="aboutt-image"
            className="max-[375px]:w-full w-[100px] md:w-[118px] xl:w-full h-auto object-contain"
          />
        </div>
        <div className="xl:col-span-2 md:col-span-3 col-span-4 text-center ">
          <StaticImage
            src="../../assets/logo/9.png"
            alt="aboutt-image"
            className="max-[375px]:w-full w-[100px] md:w-[118px] xl:w-full h-auto object-contain"
          />
        </div>
        <div className="xl:col-span-2 md:col-span-3 col-span-4 text-center">
          <StaticImage
            src="../../assets/logo/10.png"
            alt="about-image-13"
            className="max-[375px]:w-full w-[100px] md:w-[118px] xl:w-full h-auto object-contain"
          />
        </div>
        <div className="xl:col-span-2 md:col-span-3 col-span-4 text-center ">
          <StaticImage
            src="../../assets/logo/11.png"
            alt="aboutt-image"
            className="max-[375px]:w-full w-[100px] md:w-[118px] xl:w-full h-auto object-contain"
          />
        </div>
        <div className="xl:col-span-2 md:col-span-3 col-span-4 text-center ">
          <StaticImage
            src="../../assets/logo/12.png"
            alt="aboutt-image"
            className="max-[375px]:w-full w-[100px] md:w-[118px] xl:w-full h-auto object-contain"
          />
        </div>
      </div>
      <div className="lg:mx-[8%] md:mx-16 mx-4 font-jakarta my-4">
        <h3
          className=" uppercase font-jakarta md:text-2xl text-xl font-semibold py-4 border-b border-[#ffffff1a] mb-12 sm:mb-4 flex items-center justify-between text-white gap-[50px]"
          onClick={() => setIsShow(!isShow)}
        >
          Full Brand & Agency Client List
          <span
            className={` mb-[-8px] sm:mb-[-16px] rotate-0 transition-all duration-500 ${
              isShow && ' rotate-180'
            }`}
          >
            {isShow ? <MinusIcon /> : <PlusIcon />}
          </span>
        </h3>
        <div
          className="overflow-hidden transition-[max-height] duration-500"
          style={{
            maxHeight: isShow ? `${accordianHeight}px` : '0px',
          }}
        >
          <div className="md:grid md:grid-cols-2 " id="accordianRef">
            <div className=" col-span-1 ">
              {CLIENT_LIST.map(({ client }, index) => (
                <p className="" key={index}>
                  {client}
                </p>
              ))}
            </div>
            <div className=" col-span-1">
              {CLIENT_LIST2.map(({ client }, index) => (
                <p key={index}>{client}</p>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between my-12 md:my-36 xxl:my-52 lg:gap-16 md:gap-12 gap-5">
        <div className=" w-[28%] xl:w-[30%] lg:mt-52 md:mt-32 mt-20">
          <StaticImage
            src="../../assets/about-image-4.jpg"
            alt="aboutt-image"
            className="w-full h-auto xl:h-[40vw] bg-cover object-cover"
          />
        </div>
        <div className="w-[64%] xl:w-[64%]">
          {/* <div className=" absolute w-full h-full z-10" /> */}
          {/* <ReactPlayer
            className="react-player"
            url={
              data?.vimeoVideos?.[2] ||
              'https://videos.ctfassets.net/xu8n095wfb6m/4untaFsJ4PcTxZXglmDzfQ/0908ad1bf829309f67aaa0ee75090d40/jp-morgan-chase-mi-golondrina.mp4'
            }
            controls={false}
            playing
            width="100%"
            height="100%"
            muted
            loop
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              objectFit: 'contain',
              width: '100%',
              height: '100%',
            }}
          /> */}
          <video
            loop
            autoPlay
            data-autoplay
            id="vid1"
            muted
            preload="auto"
            data-keepplaying
            playsInline
            controls={false}
            className="aboutvVideo1 w-full"
          >
            <source
              src={data?.videos?.[1]?.url}
              data-wf-ignore="true"
              type="video/mp4"
            />
          </video>
          {/* <StaticImage
            src="../../assets/about-image-5.jpg"
            alt="aboutt-image"
            className="w-full h-auto xl:h-[calc(40vw)] bg-cover object-cover"
          /> */}
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query AboutPage {
    allContentfulAbout {
      nodes {
        title
        videos {
          url
        }
        vimeoVideos
        thumbImgs {
          id
          contentful_id
          gatsbyImage(height: 1440, width: 2560)
        }
      }
    }
  }
`;

export default AboutUs;
