export const CLIENT_LIST = [
  {
    client: ' ABC',
  },
  {
    client: 'Adidas ',
  },
  {
    client: 'Air New Zealand',
  },
  {
    client: 'Apple',
  },
  {
    client: 'AT&T',
  },
  {
    client: 'Amazon Web Services',
  },
  {
    client: 'Associated Press',
  },
  {
    client: 'Bank of America',
  },
  {
    client: 'Baylor College of Medicine',
  },
  {
    client: 'Beats by Dre',
  },
  {
    client: 'CNN',
  },
  {
    client: 'Coca-Cola',
  },
  {
    client: 'Dell',
  },
  {
    client: 'Diageo, Crown Royal',
  },
  {
    client: "Dicks's Sporting Goods",
  },
  {
    client: 'Discovery, Inc.',
  },
  {
    client: 'EA Sports',
  },
  {
    client: 'Nestle, Essentia Water',
  },
  {
    client: 'Exxon  Mobile',
  },
  {
    client: 'Ford Motor Company',
  },
  {
    client: 'Freeport LNG',
  },
  {
    client: 'MAX',
  },
  {
    client: 'Hotel Indigo',
  },
  {
    client: 'IBM',
  },
];

export const CLIENT_LIST2 = [
  {
    client: 'Intel',
  },
  {
    client: 'JP Morgan Chase',
  },
  {
    client: 'Kraft Heinz Company  ',
  },
  {
    client: 'Marriott International',
  },
  {
    client: 'MD Anderson Cancer Center',
  },
  {
    client: 'Microsoft',
  },
  {
    client: 'NBC',
  },
  {
    client: 'New Balance',
  },
  {
    client: 'New Era',
  },
  {
    client: 'NFL',
  },
  {
    client: 'Nike',
  },
  {
    client: 'Olympics Commitee ',
  },
  {
    client: 'Pathway Vet Alliance',
  },
  {
    client: 'PDC Brands, Dr Teal’s',
  },
  {
    client: 'PGA',
  },
  {
    client: 'Phillips',
  },
  {
    client: 'Rolls Royce',
  },
  {
    client: 'Shell',
  },
  {
    client: 'Snapchat',
  },
  {
    client: 'T-Mobile',
  },
  {
    client: 'The Mission Continues',
  },
  {
    client: 'Vice',
  },
  {
    client: 'WeTransfer',
  },
  {
    client: 'Xylem',
  },
];
